import React from 'react'
import InputMask from 'react-input-mask'
import { has } from 'lodash'

const Input = ({
    type = 'text',
    name = undefined,
    disabled = false,
    className = 'form-control',
    outerClassReplace = 'form-group ',
    outerClassName = '',
    help = false,
    value = '',
    attributes: _attributes = [],
    label = '',
    label_attrs = {},
    wrap = true,
    required = false,
    updateMethod = undefined,
    isInteger = false,
    mask,
    ...others
}) => {

    const {
        id = name
    } = others

    // Required to be set
    const attributes = {
        type,
        className,
        id,
        name,
        value,
        required,
        disabled,
        mask,
        ..._attributes,
    }

    let input_obj = null

    const renderHelpBlock = (help) => {
        if (!help) {
            return null
        }

        return (
            <div className="help-block">{help}</div>
        )
    }

    if (has(attributes, 'value.amount')) {
        attributes.value = attributes.value.amount
    }

    if (updateMethod) {
        attributes.onChange = updateMethod
    }

    const {withIcon = false, ...input_attributes} = attributes

    input_attributes['data-parsley-errors-container'] = `#${id}-parsley-error`

    if (!input_attributes.value) {
        input_attributes.value = ''
    }

    if (type == 'email') {
        // For email input types we need to use a pattern
        input_attributes.type = 'email'
        input_attributes.pattern = /^([\w-+]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
        input_attributes['data-parsley-pattern-message'] = 'This value should be a valid email.'
    }

    switch (withIcon) {
        case 'money':
            input_obj = (
                <div className="input-group">
                    <span className="input-group-addon">$</span>
                    <input {...input_attributes} />
                </div>
            )
            break
        case 'number':

            const _label = label.replace(/\s?\(\s?optional\)\s?/i, '')

            // input_attributes['data-parsley-pattern'] = '^[1-9][0-9]*0$'
            // input_attributes['data-parsley-pattern-message'] = `Value must be a interval of 10`
            input_attributes.pattern = '^[0-9]+$'

            input_attributes['data-parsley-pattern'] = '^[0-9]+$'
            input_attributes['data-parsley-pattern-message'] = `Please only use whole numbers for the "${_label}" field.`
            input_attributes['data-parsley-type-message'] = `Please only use whole numbers for the "${_label}" field.`

            input_obj = (
                <div className="input-group">
                    <span className="input-group-addon">$</span>
                    <input {...input_attributes} />
                    <span className="input-group-addon">.00</span>
                </div>
            )
            break
        case 'percent':
            isInteger ? input_attributes.step = '1' : input_attributes.step = '.0001'
            isInteger ? input_attributes['data-parsley-type-message'] = `Only whole numbers allowed`: null

            input_obj = (
                <div className="input-group">
                    <input {...input_attributes} />
                    <span className="input-group-addon">%</span>
                </div>
            )
            break
        case 'date':
            input_attributes.placeholder = 'MM/DD/YYYY'
            input_obj = (
                <div className="input-group">
                    <input {...input_attributes} />
                    <span className="input-group-addon"><i className="far fa-calendar-alt"/></span>
                </div>
            )
            break
        case 'time':
            input_attributes.placeholder = 'HH:MM AM/PM'
            input_obj = (
                <div className="input-group">
                    <input {...input_attributes} />
                    <span className="input-group-addon"><i className="fa fa-clock-o"/></span>
                </div>
            )
            break
        default:
            input_obj = <input {...input_attributes} />
    }

    const moveCursorToEndOfUserInput = (e) => {
        const {value} = e.target
        const indexEndOfUserInput = value.replaceAll('_', '').length

        e.target.setSelectionRange(indexEndOfUserInput, indexEndOfUserInput)
    }

    if (attributes.mask) {
        return (
        <div className={`${outerClassReplace} ${outerClassName}`}>
            <label htmlFor={attributes.id} className="control-label" {...label_attrs}>{label}</label>
            <InputMask
                {...attributes}
                {...input_attributes}
                onClick={attributes.moveCursorToEndOfUserInput && moveCursorToEndOfUserInput}
            />
            <span id={`${id}-parsley-error`}/>
            {renderHelpBlock(help)}
        </div>)
    }

    return !wrap ? input_obj : (
        <div className={`${outerClassReplace} ${outerClassName}`}>
            <label htmlFor={attributes.id} className="control-label" {...label_attrs}>{label}</label>
            {input_obj}
            <span id={`${id}-parsley-error`}/>
            {renderHelpBlock(help)}
        </div>
    )
}

export default Input