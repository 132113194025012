import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Input from 'components/partials/Fields/Input';
import Select from 'components/partials/Fields/Select';
import Countries from 'components/partials/Address/CountriesAndStates';
import { setSelectedState } from 'actions';

import 'styles/Auctions/TopSearchBar.less';
import CitiesSelect from "./AuctionSearchPage/CitiesSelect";

class TopSearchForm extends Component {
    input_updated = async (e, do_update = false) => {
        await this.props.search_obj_updated(e);

        if (do_update) {
            this.submitForm();
        }
    };

    /**
     * type needs to be updated in the search object first. if not then a race condition happens
     * and the term cleared out then re-set to the original value.
     * @param e
     * @returns {Promise<void>}
     */
    typeChange = async (e) => {
        const { name, value } = e.target;

        if (this.props.setSearchState) {
            return this.props.setSearchState({
                ...this.props.searchState,
                search_obj: {
                    ...this.props.searchState.search_obj,
                    term: '',
                    term_state: '',
                    term_city: '',
                    radius: this.props.defaultRadius,
                    type: value
                }
            })
        }

        await this.input_updated(e);
        await this.input_updated({ target: { name: 'term', value: '' } });
        await this.input_updated({ target: { name: 'term_state', value: '' } });
        await this.input_updated({ target: { name: 'term_city', value: '' } });

        if (name == 'type' && value != 'zipcode') {
            await this.input_updated({ target: { name: 'radius', value: this.props.defaultRadius } });
        }
    };

    renderSecondHalf = () => {
        const groupClose = (
          <div key="groupClose" className="input-group-btn">
            <button
                disabled={this.props.disabled}
                className="btn btn-blue main-search-submit"
                id="main-search-submit" type="submit">
                <i className="fa fa-search fa-2x" />
            </button>
          </div>
        );

        const {
            term = '',
            term_state = { label: '' },
            radius = '',
            type = 'zipcode',
            term_city
        } = this.props.search_obj;

        const {
            searchRadius = [],
            getStateName
        } = this.props;

        let radius_field = null;

        if (type !== 'state') {
            if (type === 'city') {
                return <>
                    <CitiesSelect
                        disabled={this.props.disabled}
                        name="term_city"
                        onChange={e => this.input_updated(e, true)}
                        value={term_city && {label: term_city, value: term_city}}
                    />
                    {groupClose}
                </>
            }
            if (type === 'zipcode') {
                radius_field = (
                  <Select
                      disabled={this.props.disabled}
                    name="radius"
                    outerClass="input-group-btn"
                    value={radius}
                    className="radius-search custom-select-arrow form-control"
                    updateMethod={this.input_updated}
                    instruction={false}
                    options={searchRadius}
                  />
                );
            }

            return (
              <>
                <Input
                    disabled={this.props.disabled}
                  wrap={false}
                  key="term"
                  name="term"
                  value={term}
                  updateMethod={this.input_updated}
                  className="form-control main-search"
                  attributes={{
                            placeholder: 'Enter search term(s)',
                        }}
                />
                {radius_field}
                {groupClose}
              </>
            );
        }

        let {
            label = '',
        } = term_state;

        let do_lookup = false;

        if (!label) {
            do_lookup = true;
            label = term_state;
        }

        if (typeof label !== 'string') {
            label = '';
        }

        return (
          <>
            <Countries
                disabled={this.props.disabled}
              key="searchCountry"
              name="term_state"
              do_lookup={do_lookup}
              getStateName={getStateName}
              value={label}
              updateMethod={(e) => this.input_updated(e, true)}
            />
            {groupClose}
          </>
        );
    };

    getSearchTypes = () => (
        this.props.customSearchTypes
            ? this.props.customSearchTypes
            : this.props.searchTypes
    );

    submitForm = () => {
        const { search_obj, redoSearch } = this.props;
        search_obj.page_num = 1;
        redoSearch && redoSearch({ search_obj });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.submitForm();
    };

    render() {
        const {
            type = '',
        } = this.props.search_obj;

        return (
          <div className="top-search-container">
            <form className="" onSubmit={this.handleSubmit}>
              <Select
                disabled={this.props.disabled}
                outerClass="form-group hidden-sm hidden-md hidden-lg"
                name="type"
                id="type-sm"
                value={type}
                className="zip-search custom-select-arrow form-control"
                updateMethod={this.typeChange}
                options={this.getSearchTypes()}
                instruction={false}
              />

              <div className="input-group input-group-lg1">
                <Select
                    disabled={this.props.disabled}
                  outerClass="input-group-btn hidden-xxs hidden-xs"
                  name="type"
                  id="type-lg"
                  value={type}
                  className="zip-search custom-select-arrow form-control"
                  updateMethod={this.typeChange}
                  options={this.getSearchTypes()}
                  instruction={false}
                />
                {this.renderSecondHalf()}
              </div>
            </form>
          </div>
        );
    }
}

TopSearchForm.defaultProps = {
    search_obj: {},
    customSearchTypes: false,
    redoSearch: false,
    defaultRadius: 100,
    search_obj_updated() {
    },
};

const mapStateToProps = ({ auction, page }) => ({
        searchRadius: auction.searchRadius,
        searchTypes: auction.searchTypes,
        radius: auction.radius,
        term: auction.term,
        windowWidth: page.windowWidth,
    });
export default connect(mapStateToProps, { setSelectedState })(TopSearchForm);
