import React from 'react';
import AsyncSelect from "react-select/async";
import useService from "hooks/useService";

const LoadingIndicator = () => <i className='fa fa-spinner fa-spin marginR15' />;

const CitiesSelect = ({
    defaultValue,
    onChange,
    name = 'cities-select',
    value,
    disabled
}) => {
    const [{}, fireSearchCity] = useService()

    const loadOptions = async (e, callback) => {
        if (e.length > 2) {
            const data = await fireSearchCity({
                url: '/hooks/autocomplete/cities',
                params: {
                    search_term: e
                }
            })

            const options = data?.cities?.map(c => ({label: c, value: c})) || []
            callback(options)
        }
    };

    return (
        <div className="text-left">
            <AsyncSelect
                components={{
                    LoadingIndicator,
                    DropdownIndicator: null,
                    Group: () => <div></div>,
                }}
                loadOptions={loadOptions}
                value={value}
                onChange={v => onChange({target: {...v, name}})}
                className="countries-select"
                isClearable
                placeholder="Enter search term(s)"
                name={name}
                cacheOptions
                defaultValue={defaultValue}
                theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    spacing: {
                        ...theme.spacing,
                        controlHeight: 50,
                    },
                })}
                isDisabled={disabled}
            />
        </div>
    );
};

export default CitiesSelect;
