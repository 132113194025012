import { forEach, get, set } from "lodash";
import React, { Component } from "react";

class Select extends Component {
    renderHelpBlock = (help) => {
        if (!help) {
            return null;
        }

        return (
            <div className="help-block">{help}</div>
        );
    };

    handleOnChange = (e) => {
        const { [this.props.name]: field } = this.refs;
        const { value, name } = field;

        this.props.updateMethod &&
        this.props.updateMethod({ target: { name, value } });
    };

    render () {
        const {
            name,
            id = name,
            className = "form-control",
            value = '',
            updateMethod,
            required = false,
            custom = false,
            label = false,
            help = false,
            disabled = false,
            outerClass = '',
            optionValue = 'id',
            optionLabel = 'name',
            options = [],
            attributes: propsAttributes = [],
            instruction: instructionText = 'Please Select',
            instruction_disabled = false,
        } = this.props;

        const attributes = {
            name,
            id,
            className,
            value: value || '',
            required,
            onChange: updateMethod,
        };

        forEach(propsAttributes, (item, key) => {
            set(attributes, key, item);
        });

        attributes.onChange = this.handleOnChange;
        attributes.ref = attributes.name;

        let instruction = null;
        if (instructionText !== false) {
            instruction = (
                <option value="" disabled={instruction_disabled}>
                    {instructionText}
                </option>
            );
        }

        let customInstruction = null;
        if (custom !== false) {
            customInstruction = (<option value="">{custom}</option>);
        }

        return (
            <div className={outerClass}>
                {label !== false &&(
                    <label htmlFor={attributes.id}
                             className={`control-label label-${attributes.id}`}>
                        {label}
                    </label>
                )}
                <select {...attributes} disabled={this.props.disabled} >
                    {instruction}
                    {customInstruction}
                    {options.map((item, index) => (
                        <option key={index} value={get(item, optionValue)}>
                            {get(item, optionLabel)}
                        </option>
                    ))}
                </select>
                {this.renderHelpBlock(help)}
            </div>
        );
    }
}

Select.defaultProps = {
    label: false,
    outerClass: "form-group",
    updateMethod: undefined,
};

export default Select;
